/**
 * @fileoverview gRPC-Web generated client stub for shareHealthApi
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.18.1
// source: shareHealth.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.shareHealthApi = require('./shareHealth_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.shareHealthApi.ShareHealthProtoServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.shareHealthApi.ShareHealthProtoServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.shareHealthApi.LoginHealthProfessionalRequest,
 *   !proto.shareHealthApi.LoginHealthProfessionalResponse>}
 */
const methodDescriptor_ShareHealthProtoService_LoginHealthProfessional = new grpc.web.MethodDescriptor(
  '/shareHealthApi.ShareHealthProtoService/LoginHealthProfessional',
  grpc.web.MethodType.UNARY,
  proto.shareHealthApi.LoginHealthProfessionalRequest,
  proto.shareHealthApi.LoginHealthProfessionalResponse,
  /**
   * @param {!proto.shareHealthApi.LoginHealthProfessionalRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.shareHealthApi.LoginHealthProfessionalResponse.deserializeBinary
);


/**
 * @param {!proto.shareHealthApi.LoginHealthProfessionalRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.shareHealthApi.LoginHealthProfessionalResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.shareHealthApi.LoginHealthProfessionalResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.shareHealthApi.ShareHealthProtoServiceClient.prototype.loginHealthProfessional =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/shareHealthApi.ShareHealthProtoService/LoginHealthProfessional',
      request,
      metadata || {},
      methodDescriptor_ShareHealthProtoService_LoginHealthProfessional,
      callback);
};


/**
 * @param {!proto.shareHealthApi.LoginHealthProfessionalRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.shareHealthApi.LoginHealthProfessionalResponse>}
 *     Promise that resolves to the response
 */
proto.shareHealthApi.ShareHealthProtoServicePromiseClient.prototype.loginHealthProfessional =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/shareHealthApi.ShareHealthProtoService/LoginHealthProfessional',
      request,
      metadata || {},
      methodDescriptor_ShareHealthProtoService_LoginHealthProfessional);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.shareHealthApi.EmptyRequest,
 *   !proto.shareHealthApi.DeleteTokenProfessionalResponse>}
 */
const methodDescriptor_ShareHealthProtoService_DeleteTokenProfessional = new grpc.web.MethodDescriptor(
  '/shareHealthApi.ShareHealthProtoService/DeleteTokenProfessional',
  grpc.web.MethodType.UNARY,
  proto.shareHealthApi.EmptyRequest,
  proto.shareHealthApi.DeleteTokenProfessionalResponse,
  /**
   * @param {!proto.shareHealthApi.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.shareHealthApi.DeleteTokenProfessionalResponse.deserializeBinary
);


/**
 * @param {!proto.shareHealthApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.shareHealthApi.DeleteTokenProfessionalResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.shareHealthApi.DeleteTokenProfessionalResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.shareHealthApi.ShareHealthProtoServiceClient.prototype.deleteTokenProfessional =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/shareHealthApi.ShareHealthProtoService/DeleteTokenProfessional',
      request,
      metadata || {},
      methodDescriptor_ShareHealthProtoService_DeleteTokenProfessional,
      callback);
};


/**
 * @param {!proto.shareHealthApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.shareHealthApi.DeleteTokenProfessionalResponse>}
 *     Promise that resolves to the response
 */
proto.shareHealthApi.ShareHealthProtoServicePromiseClient.prototype.deleteTokenProfessional =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/shareHealthApi.ShareHealthProtoService/DeleteTokenProfessional',
      request,
      metadata || {},
      methodDescriptor_ShareHealthProtoService_DeleteTokenProfessional);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.shareHealthApi.EmptyRequest,
 *   !proto.shareHealthApi.VerifyTokenResponse>}
 */
const methodDescriptor_ShareHealthProtoService_VerifyToken = new grpc.web.MethodDescriptor(
  '/shareHealthApi.ShareHealthProtoService/VerifyToken',
  grpc.web.MethodType.UNARY,
  proto.shareHealthApi.EmptyRequest,
  proto.shareHealthApi.VerifyTokenResponse,
  /**
   * @param {!proto.shareHealthApi.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.shareHealthApi.VerifyTokenResponse.deserializeBinary
);


/**
 * @param {!proto.shareHealthApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.shareHealthApi.VerifyTokenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.shareHealthApi.VerifyTokenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.shareHealthApi.ShareHealthProtoServiceClient.prototype.verifyToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/shareHealthApi.ShareHealthProtoService/VerifyToken',
      request,
      metadata || {},
      methodDescriptor_ShareHealthProtoService_VerifyToken,
      callback);
};


/**
 * @param {!proto.shareHealthApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.shareHealthApi.VerifyTokenResponse>}
 *     Promise that resolves to the response
 */
proto.shareHealthApi.ShareHealthProtoServicePromiseClient.prototype.verifyToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/shareHealthApi.ShareHealthProtoService/VerifyToken',
      request,
      metadata || {},
      methodDescriptor_ShareHealthProtoService_VerifyToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.shareHealthApi.RefreshTokenRequest,
 *   !proto.shareHealthApi.RefreshTokenResponse>}
 */
const methodDescriptor_ShareHealthProtoService_RefreshToken = new grpc.web.MethodDescriptor(
  '/shareHealthApi.ShareHealthProtoService/RefreshToken',
  grpc.web.MethodType.UNARY,
  proto.shareHealthApi.RefreshTokenRequest,
  proto.shareHealthApi.RefreshTokenResponse,
  /**
   * @param {!proto.shareHealthApi.RefreshTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.shareHealthApi.RefreshTokenResponse.deserializeBinary
);


/**
 * @param {!proto.shareHealthApi.RefreshTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.shareHealthApi.RefreshTokenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.shareHealthApi.RefreshTokenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.shareHealthApi.ShareHealthProtoServiceClient.prototype.refreshToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/shareHealthApi.ShareHealthProtoService/RefreshToken',
      request,
      metadata || {},
      methodDescriptor_ShareHealthProtoService_RefreshToken,
      callback);
};


/**
 * @param {!proto.shareHealthApi.RefreshTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.shareHealthApi.RefreshTokenResponse>}
 *     Promise that resolves to the response
 */
proto.shareHealthApi.ShareHealthProtoServicePromiseClient.prototype.refreshToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/shareHealthApi.ShareHealthProtoService/RefreshToken',
      request,
      metadata || {},
      methodDescriptor_ShareHealthProtoService_RefreshToken);
};


module.exports = proto.shareHealthApi;

