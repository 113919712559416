import { ShareHealthProtoServicePromiseClient } from '../grpc/generated/shareHealth_grpc_web_pb';

const apiUrl = process.env.VUE_APP_API_URL;
const svc = new ShareHealthProtoServicePromiseClient(apiUrl);


async function verifyToken(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.verifyToken(request, metadata, {});

    return response.toObject();
}

async function refreshToken(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.refreshToken(request, metadata, {});

    return response.toObject();
}

async function refreshTokenSecondary(request) {
    const token = window.localStorage.getItem("token_secondary");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.refreshToken(request, metadata, {});

    return response.toObject();
}

async function deleteToken(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.deleteTokenProfessional(request, metadata, {});

    return response.toObject();
}

async function loginHealth(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.loginHealthProfessional(request, metadata, {});

    return response.toObject();
}

export default {
    verifyToken,
    refreshToken,
    refreshTokenSecondary,
    deleteToken,
    loginHealth,
}