// Professionalprofile
import professionalProfileService from "../../core/services/professionalProfileService"
import pProfile from "../../core/grpc/generated/professionalProfile_pb.js"
// Appointment
import appointmentService from "../../core/services/appointmentService"
import { TodayMedicalCareRequest } from "../../core/grpc/generated/appointment_pb.js"
// ShareHealth
import shareHealthService from "../../core/services/shareHealthService"
import { RefreshTokenRequest, LoginHealthProfessionalRequest, EmptyRequest } from "../../core/grpc/generated/shareHealth_pb.js";

const VerifyToken = async () => {
    try {
        const req = new EmptyRequest();
        const res = await shareHealthService.verifyToken(req);

        // console.log("VerifyToken", res);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

const RefreshToken = async (patientId) => {
    try {
        const req = new RefreshTokenRequest();
        req.setPatientid(patientId)
        const res = await shareHealthService.refreshToken(req);
        // console.log("RefreshTokenActions", res)

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

const TodayMedicalCare = async () => {
    try {
        const req = new TodayMedicalCareRequest();
        req.setStatus("Todos")
        const res = await appointmentService.todayMedicalCare(req);

        // console.log("TodayMedicalCare:", res);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

const GetInfo = async () => {
    try {
        const req = new pProfile.EmptyRequest();
        const res = await professionalProfileService.getInfo(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

const DeleteToken = async () => {
    try {
        console.log();
        const req = new EmptyRequest();
        const res = await shareHealthService.deleteToken(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

const LoginHealth = async (password) => {
    try {
        const req = new LoginHealthProfessionalRequest();
        req.setPassword(password)
        const res = await shareHealthService.loginHealth(req);

        return res;
    } catch (error) {
        return error;
    }
}

export {
    VerifyToken,
    RefreshToken,
    TodayMedicalCare,
    GetInfo,
    DeleteToken,
    LoginHealth,
}